import styled from 'styled-components';

export const StyledModalPopupContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${props => props.theme.zIndex.LayerTen};
    background: ${ props =>props.coverSetting ? '#fff' : 'rgba(0,0,0,0.8)' };

    .modalPanel {
        width: 560px;
        height: 360px;
        background: #FFFFFF;
        border-radius: 12px;
    }

    .topSection {
        padding: 8px;
        width: 100%;
        height: 48px;
        background: ${props =>props.type === 'delete'
        ? 'linear-gradient(90deg, #F34F66 0%, #E93952 97.76%)'
        : props.type ===  'file-delete' ? 'linear-gradient(90deg, #F34F66 0%, #E93952 97.76%)' : 'linear-gradient(90deg, #F381B1 0%, #BB86FC 100%)'};
        border-radius: 12px 12px 0px 0px;
        svg {
            font-size: 30px;
            fill: #fff;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .bottomSection {
        padding: 32px;
        width: 100%;
        height: calc(100% - 48px);
    }

    .iconPanel {
        margin-bottom: 29px;

        img {
            width: 72px;
            height: 72px;
        }
    }

    .descPanel {
        margin-bottom: 4px;
        color: #333;
        font-weight: 500;
        font-size: 15px;
    }

    .noticePanel {
        margin-bottom: 50px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        font-size: 13px;
    }

    .cancelButton {
        width: 77px;
        height: 40px;
        background: rgba(0, 0, 0, 0.05);
        order:${props => props.type === 'file-save' ? 1 : props.type === 'file-preview' ? 1 : 2};
        &:hover {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.12);
            border: 1px solid #E6E6E6;
            border-radius: 4px;
        }
    }
    .confirmButtons{
        width: 270px;
    }
    .confirmButton {
        min-width: 77px;
        height: 40px;
        background: ${props => props.type === 'delete' ? '#F5314D' : props.type === 'file-delete' ? '#F5314D' : '#8858C8'};
        order:${props => props.type === 'file-save' ? 2 : props.type === 'file-preview' ? 2 : 1};
        border-radius: 4px;
        color: #FFFFFF;
        padding:0 20px;
        &:hover {
            cursor: pointer; 
            background: ${props => props.type === 'delete' ? '#F34F66' : props.type === 'file-delete' ? '#F34F66' : '#BB86FC'};
        }
    }

    .okButton {
        background: #ED0973;
        border-radius: 4px;
        padding:12px 24px;
        color: #fff;

        &:hover {
            cursor: pointer;
        }
    }

`;