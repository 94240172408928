import styled from 'styled-components';

export const StyledMobilePopup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #1F1F1F;

    .mobile_popup {

        max-width: 240px;

        .copySuccess{
            width: 230px;
            background: #54C68F;
            border-radius: 4px;
            padding: 8px 24px;
            color: #FFFFFF;
            line-height: 140%;
            position: absolute;
            top: calc((100vh - 488px) / 2);
            left: calc((100vw - 230px) / 2);
            display: flex;
            align-items: center;
            svg{
                margin-right: 4px;
            }
        }

        &_icon {
            width: 32px;
            height: 32px;
            margin: 0 auto 16px auto;
        }

        &_icon_loading {
            animation: rotation 1s infinite;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        &_text {
            padding: 0px 24px;
            margin-bottom: 32px;
            word-break: normal;
        }

        &_title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.87);
            margin-bottom: 8px;
        }

        &_description {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.6);
        }

        &_link {
            text-align: left;

            .title {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.004em;
                color: #FFFFFF;
                margin-bottom: 16px;
            }
            
            .copyLink {

                display: flex;
                flex-direction: row;
                column-gap: 8px;
                align-items: center;
                margin-bottom: 24px;

                > input {
                    width: 100%;
                    padding: 8px 16px;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000;
                    border: 1px solid #CCCCCC;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.004em;
                    color: #000000;
                }

                .copyLinkSvg {
                    padding: 8px;
                    background: #E51768;
                    border: 1px solid #E51768;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    > svg {
                        color: #FFFFFF;
                    }
                }
            }
        }

        &_button {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.8);
            border: none;
            background: transparent;
            padding: 8px 24px;
            text-decoration: underline;
        }

        &_button_disabled {
            color: rgba(255, 255, 255, 0.2);
        }
    }
`;