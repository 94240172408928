import React, { useEffect, useState } from 'react';

// styled components
import { StyledSideBarNav } from './StyledSideBarNav';

// image
import defaulImg from './images/defaul.svg';

// plugin
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';

// react icon
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';

const SideBarNav = (props) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [icon, setIcon] = useState('');
    const [modelListSort, setModelList] = useState([]);

    useEffect(() => {

        let sortAry = props.modelList.sort((a, b) => {
            return dayjs(b.lastUpdated).unix() - dayjs(a.lastUpdated).unix();
        });

        for (let i = 0; i < sortAry.length; i++) {
            if (sortAry[i].mainObject === true) {
                let mainTemp = sortAry[i];
                sortAry[i] = sortAry[0];
                sortAry[0] = mainTemp;
            }
        }

        setModelList(sortAry);



    }, [props.modelList]);

    const changeModelEvent = (e) => {
        if (props.loadingStatus) {
            return;
        }
        if (props.modelIndex == e.currentTarget.getAttribute('data-index')) {
            return;
        }
        props.setloadingStatus(true);
        let allModelImage = document.querySelectorAll('.modelImage');
        allModelImage.forEach((item) => {
            item.style.border = 'none';
        });
        e.currentTarget.style.border = '1px solid #FFFFFF';
        // 跳轉其他模型時，要初始化相機角度，不可移除
        if (urlParams.get('modelIndex')) {
            urlParams.set('modelIndex', parseInt(e.currentTarget.getAttribute('data-index')));
            console.log(urlParams.toString());
            window.open(`${window.location.protocol + '//' + window.location.host}/?${urlParams.toString()}`, '_self');
        } else {
            window.open(`${window.location.href}&modelIndex=${parseInt(e.currentTarget.getAttribute('data-index'))}`, '_self');
        }
        // props.setIndex(parseInt(e.currentTarget.getAttribute('data-index')));
    };

    return (
        <StyledSideBarNav style={{ bottom: props.showBar ? 0 : '-94px' }} loadingStatus={props.loadingStatus}>
            {
                !props.showBar && <div className="openMobileBar" onClick={() => { props.setShowBar(true); }}>
                    <MdOutlineKeyboardArrowUp />
                </div>
            }
            {
                props.showBar && <div className="closeMobileBar" onClick={() => { props.setShowBar(false); }}>
                    <MdOutlineKeyboardArrowDown />
                </div>
            }
            {
                <div className="MobileImageContainer">
                    {modelListSort?.map((model, index) => {
                        return <img onClick={changeModelEvent} data-index={index} key={model.id} src={model.icon !== '' ? model.icon : defaulImg} alt="" className="modelImage" />;

                    })}
                </div>
            }
        </StyledSideBarNav>
    );
};

export default SideBarNav;
