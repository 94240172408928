import { USER_INFO } from 'redux/types/userInfo.types';

let USER_INFO_RES = {
    userInfo: '',
};

const userInfoReducer = ({ type, payload } = {}, state = USER_INFO_RES) => {
    switch (type) {
        case USER_INFO:
            return {
                ...state, userInfo: payload,
            };
        default: return state;
    }
};

export default userInfoReducer;