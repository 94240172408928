import { useState, useEffect, useMemo } from 'react';
import { getQueryString } from 'utils/url';

// styles
import { StyledTutorialContainer } from './StyledTutorial';

// components
import QRcode from 'components/QRcode/QRcode';
import MobilePopup from 'components/MobilePopup/MobilePopup';

//img
import { ReactComponent as TouchIcon } from './images/touch.svg';
import { ReactComponent as PcAr } from './images/pcAr.svg';
import close from './images/close.svg';
import z2 from './images/2.svg';
import r2 from './images/4.svg';
import m2 from './images/6.svg';

// i18n
import { useTranslation } from 'react-i18next';

const Tutorial = (props) => {

    const { t } = useTranslation();

    const [showRemind, setShowRemind] = useState(false);
    const [fullBool, setFull] = useState(false);
    const isVisionPro = useMemo(() => getQueryString('device') === 'vision-pro', []);

    useEffect(() => {
        if (window.innerWidth > 1024) {
            if (fullBool === true) {
                if (document.documentElement.webkitRequestFullScreen) {
                    document.documentElement.webkitRequestFullScreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.msRequestFullscreen) {
                    document.documentElement.msRequestFullScreen();
                }

            } else {
                if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }

            }
        }
    }, [fullBool]);


    const remindEvent = () => {
        if (props.loadingStatus) {
            return;
        }
        setShowRemind(!showRemind);
    };

    const fullEvent = () => {
        if (props.loadingStatus) {
            return;
        }
        setFull(!fullBool);
    };

    const [isMobile, setIsMobile] = useState(false);
    const [isQrcode, setQrcode] = useState(false);
    const [mobilePopup, setMobilePopup] = useState(null);

    useEffect(() => {
        const mobileDevice = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone'];
        let isMobileDevice = mobileDevice.some(e => navigator.userAgent.match(e));
        setIsMobile(isMobileDevice);
    }, [navigator.userAgent]);

    const showQrCode = () => {
        if (props.loadingStatus) return;

        // 偵測瀏覽器
        let isChrome = navigator.userAgent.indexOf('CriOS') > -1;
        let isSafari = navigator.userAgent.indexOf('Safari') > -1;
        if (isChrome && isSafari) isSafari = false;

        if (isMobile) {
            // 手機版裝置
            setMobilePopup('偵測裝置中');

            // 判斷使用者是否使用IOS作業系統
            if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                // 判斷物件是否缺少USDZ檔案
                if (props.noUsdz) {
                    // 缺少USDZ檔案，則無法開啟AR投放
                    setMobilePopup('無法使用');
                } else {
                    // 判斷使用者是否使用Safari瀏覽器
                    if (isSafari) {
                        setMobilePopup('偵測裝置成功');
                        const getMovelViewer = document.querySelector('#myViewer');
                        getMovelViewer.activateAR();
                    } else {
                        // 使用非Safari瀏覽器，則無法開啟AR投放
                        setMobilePopup('未使用Safari瀏覽器');
                    }
                }
            } else {
                // 其餘系統不需判斷檔案，直接開啟AR投放
                setMobilePopup('偵測裝置成功');
                const getMovelViewer = document.querySelector('#myViewer');
                getMovelViewer.activateAR();
            }
        } else {
            // 電腦版裝置
            setQrcode(true);
        }
    };

    return (
        <StyledTutorialContainer loadingStatus={props.loadingStatus} isAr={props.isAr}>
            {isQrcode && <QRcode setQrcode={setQrcode} modelIndex={props.modelIndex} />}
            {mobilePopup && <MobilePopup type={mobilePopup} modelIndex={props.modelIndex} />}
            <ul className='tutorialPanel'>
                <li onClick={remindEvent} className='flexCenter'>
                    <div className='blackRemind'>
                        <div className="tt">{t('Tutorial.gestureAssistance')}</div>
                        <div className="angle"></div>
                    </div>
                    <TouchIcon className='touchIcon' />
                    {
                        showRemind && <div className="pop">
                            <div className="top">
                                {t('Tutorial.gestureAssistance')}
                                <img src={close} onClick={remindEvent} alt="" />
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{t('Tutorial.zoom')}</td>
                                        <td>{t('Tutorial.rotate')}</td>
                                        <td>{t('Tutorial.smoothMove')}</td>
                                        <td>{t('Tutorial.default')}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={z2} alt="zoom" />
                                        </td>
                                        <td>
                                            <img src={r2} alt="rotate" />
                                        </td>
                                        <td>
                                            <img src={m2} alt="smoothMove" />
                                        </td>
                                        <td>
                                            <img src={m2} alt="default" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </li>
                {/* <li onClick={fullEvent}>
                    <div className="blackRemind">
                        <div className="tt">{t('Tutorial.fullScreen')}</div>
                        <div className="angle"></div>
                    </div>
                    {
                        fullBool ? <FullClose /> : <FullOpen />
                    }
                </li> */}
                {!isVisionPro && (
                    <li onClick={showQrCode}>
                        <div className="blackRemind">
                            <div className="tt">{t('Tutorial.ARProjection')}</div>
                            <div className="angle"></div>
                        </div>
                        <PcAr />
                    </li>
                )}
            </ul>
        </StyledTutorialContainer >
    );
};

export default Tutorial;
