import styled from 'styled-components';

export const StyledTutorialContainer = styled.div`
    position: fixed;
    right: 48px;
    top: 48px;
    width: 40px;
    z-index: ${props => props.theme.zIndex.LayerEight};

    @media all and (max-width:768px) {
        top: 15px;
        right: 15px;
    }

    .tutorialPanel {
        z-index: 10;
        li{
            position: relative;
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background-color: white;
            cursor: pointer;
            list-style: none;
            line-height: 40px;
            text-align:center;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            opacity:${props => props.loadingStatus ? '0.3' : '1'};
            color: #333333;

            a {
                color: #333333;
                text-decoration: none;
            }


            svg{
                width: 40px;
                fill: #333333;
            }

            .blackRemind{
                display:none;
                align-items:center;
                position: absolute;
                right:45px;
                top:3px;
                @media all and (max-width:1024px) {
                    display:none;
                }
                .tt{
                    background: #000;
                    height:32px;
                    line-height:32px;
                    border-radius:4px;
                    padding:0 7px;
                    color:#fff;
                    font-size:14px;
                    white-space:nowrap;
                }
                .angle{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 14px;
                    border-color: transparent transparent transparent #000000;
                }
            }

            .pop{
                background-color: rgba(0,0,0,0.87);
                width: 100vw;
                height: 100vh;
                position: fixed;
                left:0;
                top:0;
                border-radius:0;
                z-index: 11;
                .top{
                    margin-bottom: 20px;
                    font-size:20px;
                    color: rgba(255,255,255,0.87);
                    padding-top:150px;
                    @media all and (max-width:768px) {
                        padding-bottom:40px ;
                        padding-top:50px;
                        width: 100%;
                        display:block;
                        font-size:14px;
                    }
                    img{
                        width: 16px;
                        cursor: pointer;
                        position: absolute;
                        top:20px;
                        right:20px;
                    }
                }
                table{
                    margin:0 auto;
                    width: 500px;
                    @media all and (max-width:768px) {
                        width: 200px;
                    }
                    tr{
                        @media all and (max-width:768px) {
                            display:inline-block;
                        }
                        td{
                            font-size: 16px;
                            color: #fff;
                            width: 25%;
                            text-align: center;
                            word-break: break-word;
                            line-height: 18px;
                            padding-top: 10px;
                            @media all and (max-width:768px) {
                                font-size:14px;
                                display:block;
                                width: auto;
                                height:80px;
                                line-height:80px;
                            }
                            img{
                                width: 60px;
                            }
                        }
                    }
                }
            }

            &:hover{
                background-color: rgba(0,0,0,0.8);
                box-shadow:none;
                color: #fff;
                a {
                    color: #fff;
                }
                svg{
                    fill: #fff;
                }
                .blackRemind{
                    display:flex;
                    @media all and (max-width:1024px) {
                        display:none;
                    }
                }
            }

            & + li {
                margin-top: 12px;

                @media all and (max-width:768px) {
                    margin-top: 15px;
                }
            }
        }

        .touchIcon {
            width: 33px;
        }
    }
`;