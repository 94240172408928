const env = window.location.hostname === 'localhost' ? 'local' : process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV;
const PAGE_URL = {
    local: {
        AR_BILLING: 'https://ar-dev.istaging.com/',
        AR_VIEWER:'http://localhost:3021/',
        // AR_VIEWER:'http://192.168.6.195:3021/',
    },
    development: {
        AR_BILLING: 'https://ar-dev.istaging.com/',
        AR_VIEWER:'https://arviewer-dev.istaging.com/',
        // AR_VIEWER:'http://192.168.6.195:3021/',
    },
    test: {
        AR_BILLING: 'https://ar-dev.istaging.com/',
        AR_VIEWER:'https://arviewer-dev.istaging.com/',
    },
    production: {
        AR_BILLING: 'https://ar.istaging.com/',
        AR_VIEWER:'https://arviewer.istaging.com/',
    },
};

export default PAGE_URL[env];
