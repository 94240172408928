import { combineReducers } from 'redux';
import userInfoReducer from './reducers/userInfo.reducer';
// import tokenReducer from './reducers/token.reducer';
import projectInfoReducer from './reducers/projectInfo.reducer';
import modelIndexReducer from './reducers/modelIndex.reducer';

const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    // token:tokenReducer,
    projectInfo: projectInfoReducer,
    // modelIndex:modelIndexReducer,
});


export default rootReducer;