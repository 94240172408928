import { useState } from 'react';
// styled-components
import { StyledMobilePopup } from './StyledMobilePopup';

// images
import { ReactComponent as Loading } from './images/Loading.svg';
import { ReactComponent as Oops } from './images/Oops.svg';
import { ReactComponent as Success } from './images/Success.svg';
import { ReactComponent as Safari } from './images/Safari.svg';

// constants
import pageUrl from 'constants/pageUrl';

// plugin
import Cookies from 'js-cookie';

// i18n
import { useTranslation } from 'react-i18next';

// icon
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdContentCopy } from 'react-icons/md';

const MobilePopup = ({ type, modelIndex }) => {

    const { t } = useTranslation();

    let popupType;

    switch (type) {
        case '偵測裝置中':
            popupType = {
                icon: <Loading />,
                animation: true,
                title: t('MobilePopup.detectingDevice'),
                description: '',
                btnDisabled: true,
            };
            break;
        case '無法使用':
            popupType = {
                icon: <Oops />,
                animation: false,
                title: t('MobilePopup.invalid'),
                description: t('MobilePopup.invalidOnTheSystem'),
                btnDisabled: false,
            };
            break;
        case '偵測裝置成功':
            popupType = {
                icon: <Success />,
                animation: false,
                title: t('MobilePopup.detectSuccessfully'),
                description: '',
                btnDisabled: false,
            };
            break;
        case '未使用Safari瀏覽器':
            popupType = {
                icon: <Safari />,
                animation: false,
                title: t('MobilePopup.useSafari'),
                description: '', // navigator.userAgent
                btnDisabled: false,
            };
            break;
    }

    const handleClickEvent = () => {
        window.open(`${pageUrl.AR_VIEWER}?uid=${Cookies.get('uid')}&modelIndex=${modelIndex}`, '_self', 'noopener, noreferrer');
    };

    const [copyStatus, setCopyStatus] = useState(false);

    const handleCopyEvent = () => {
        navigator.clipboard.writeText(`${pageUrl.AR_VIEWER}?uid=${Cookies.get('uid')}&modelIndex=${modelIndex}&openAr=true`).then(() => {
            setCopyStatus(true);
            setTimeout(() => setCopyStatus(false), 2000);
        });
    };

    return (
        <StyledMobilePopup>
            <div className='mobile_popup'>
                {copyStatus && (
                    <div className='copySuccess'>
                        <AiFillCheckCircle />
                        {t('MobilePopup.successfullyCopied')}
                    </div>
                )}
                <div className={`mobile_popup_icon ${popupType.animation && 'mobile_popup_icon_loading'}`}>
                    {popupType.icon}
                </div>
                <div className='mobile_popup_text'>
                    <div className='mobile_popup_title'>{popupType.title}</div>
                    <div className='mobile_popup_description'>{popupType.description}</div>
                </div>
                {type === '未使用Safari瀏覽器' && (
                    <div className='mobile_popup_link'>
                        <div className='title'>{t('MobilePopup.copyLink')}</div>
                        <div className='copyLink'>
                            <input type="text" value={`${pageUrl.AR_VIEWER}?uid=${Cookies.get('uid')}&modelIndex=${modelIndex}&openAr=true`} readOnly />
                            <div className='copyLinkSvg' onClick={handleCopyEvent}>
                                <MdContentCopy />
                            </div>
                        </div>
                    </div>
                )}
                <button 
                    className={`mobile_popup_button ${popupType.btnDisabled && 'mobile_popup_button_disabled'}`} 
                    onClick={handleClickEvent} 
                    disabled={popupType.btnDisabled}
                >
                    {t('MobilePopup.NavigateToViewer')}
                </button>
            </div>
        </StyledMobilePopup>
    );
};

export default MobilePopup;