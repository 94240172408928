import styled from 'styled-components';

export const StyledApp = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: ${props => props.isAlphaBg ? 'none' : 'linear-gradient(180deg, #FBFBFB 0%, #E9E9E9 100%)'}; 
    word-break: break-all;
    overflow: ${props => props.forVRmaker ? 'auto' : 'hidden'};
    
    & * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .ar_loading {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 99999999999999999999999999;
        left:0;
        top:0;
        background: #1F1F1F;
        color: rgba(255, 255, 255, 0.87);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .flexY {
        flex-direction: column;
    }

    .hidden {
        display: none;
    }

    .flexCenter {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .flexStartCenter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .flexEndCenter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .flexBetweenCenter {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .flexCenterStart {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .flexCenterEnd {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .flexCenterBetween {
        display: flex;
        justify-content: center;
        align-items: space-between;
    }

    .flexStart {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;