import styled from 'styled-components';

export const StyledQRcode = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999999999999999;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    .closeIcon {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
        svg {
            color: #FFFFFF;
            font-size: 30px;
        }
    }
    .qrcode_container {
        width: 600px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        align-content: center;
        h1 {
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            padding-bottom: 16px;
        }
        span {
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.6);
        }
        .qrcode {
            border: 20px solid #fff;
            margin: 48px 0px;
        }
    }
`;