import styled from 'styled-components';

export const StyledVRMode = styled.div`
    .tutorialMode{
        position:fixed;
        top:0;
        width:100vw;
        height:100vh;
        background: rgba(0,0,0,0.2);
        overflow: auto;
        padding: 5%;
        
        @media(max-width:768px){
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; 
        }
        .gestureContainer{
            width: 100%;
            max-width:320px;
            display:flex;
            flex-wrap:wrap;
            margin: 10% auto;
            @media(max-width:768px){
                margin-top:20px;
            }
            .gesture{
                &_item{
                    width:50%;
                    height:100%;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    flex-direction:column;
                        margin-bottom:30px;
                    &_title{
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 140%;
                        text-align: center;
                        letter-spacing: 0.02em;
                        color: rgba(255, 255, 255, 0.87);
                        margin-bottom:30px;
                    }
                }
            }
        }
    }
    .closeTutorialMode{
        display:inline-block;
        position: relative;
        left: 50%;
        transform:translate(-50%,0);
        margin-top:70px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #FFFFFF;
        padding: 20px 70px;
        border: 2px solid #FFFFFF;
        border-radius: 30px;
        cursor:pointer;
        transition: .3s all ease;
        white-space:nowrap;
        &:hover{
            background: #FFFFFF;
            border: 2px solid #333333;
            border-radius: 30px;
            color: #333333;
            transition: .3s all ease;
        }
    }
    .qrcode_container {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        align-content: center;
        margin: 10% auto;
        h1 {
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            padding-bottom: 16px;
        }
        span {
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.6);
        }
        .qrcode {
            &_box{
                margin: 48px 0px;
                position:relative;
            }
            &_frame{
                position:absolute;
                top:-18%;
                left:-20%;
                transform:translate(-50%, -50%);
                animation:scale 1s ease infinite alternate;
            }
        }
    }
    @-webkit-keyframes scale{
    }
    @keyframes scale{
        from{
            transform:scale(0.8);
        }

        to{
            transform:scale(1);
        }
    }
`;