// react
import React from 'react';

// styled components
import { StyledModalPopupContainer } from './StyledModalPopup';

// react icons
import { AiOutlineClose } from 'react-icons/ai';



const alertType = ['alert-format', 'alert-storage', 'alert-tag-name', 'fail-save'];
const confirmType = ['file-delete', 'delete', 'exit-editor', 'exit-tag', 'file-save', 'file-preview'];

const ModalPopup = ({
    modalConfig,
    setModalSetting,
    modalSetting,
}) => {
    const { icon, type, desc, notice } = modalConfig;
    const { title } = modalSetting;

    const handleCloseModal = () => {
        if (modalSetting.handleOtherAction) {
            modalSetting.handleConfirm();
        } else {
            setModalSetting({
                ...modalSetting,
                show: false,
            });
        }
    };

    const handlModalConfirm = () => {
        modalSetting.handleConfirm();
        return setModalSetting({
            ...modalSetting,
            show: false,
        });
    };


    const confirmButtons = () => {
        return (
            <div className="confirmButtons flexBetweenCenter">
                <div className="confirmButton flexCenter" onClick={handlModalConfirm}>
                    {(type === 'file-delete' || type === 'delete') && '刪除'}
                    {(type === 'exit-editor' || type === 'exit-tag') && '離開'}
                    {type === 'file-save' && '儲存並公開'}
                    {type === 'file-preview' && '儲存並預覽'}
                </div>
                <div
                    className="cancelButton flexCenter"
                    onClick={handleCloseModal}
                >
                    取消

                </div>
            </div>
        );
    };

    const alertButton = () => {
        return (
            <div className="alertButton flexCenter">
                <div
                    className="okButton flexCenter"
                    onClick={handleCloseModal}
                >
                    {modalSetting.otherBtnText || '好的'}
                </div>
            </div>
        );
    };



    const renderDeleteDesc = () => {
        let sliceTitle = title ? title : ' ';
        if (sliceTitle.length > 15) {
            sliceTitle = `${sliceTitle.slice(0, 15)}...`;
        }
        desc[1] = `"${sliceTitle}"`;
        const joinDesc = desc.join(' ');
        return joinDesc;
    };

    const renderNormalDesc = () => {
        return desc;
    };

    return (
        <StyledModalPopupContainer className="flexCenter" type={type} coverSetting={modalSetting.coverSetting}>
            <div className="modalPanel">
                {
                    !modalSetting.coverSetting &&
                    <div
                        className="topSection flexEndCenter"
                        onClick={handleCloseModal}
                    >
                        <AiOutlineClose />
                    </div>
                }
                <div className="bottomSection flexStartCenter flex-column">
                    <div className="iconPanel">
                        <img src={icon} alt="delete icon" />
                    </div>
                    <div className="descPanel">
                        {type === 'delete' && renderDeleteDesc()}
                        {type !== 'delete' && renderNormalDesc()}
                    </div>
                    <div className='titlePanel'></div>
                    <div className="noticePanel">
                        {notice}
                    </div>
                    <div className="buttonPanel">
                        {confirmType.indexOf(type) !== -1 && confirmButtons()}
                        {alertType.indexOf(type) !== -1 && alertButton()}
                    </div>
                </div>

            </div>
        </StyledModalPopupContainer>
    );
};

export default ModalPopup;
