import styled from 'styled-components';

// img
const ARactive = '../assets/VRTutorial/ARactive.svg';
const ARactiveHover = '../assets/VRTutorial/ARactiveHover.svg';
const Touch = '../assets/VRTutorial/touch.svg';
const TouchHover = '../assets/VRTutorial/touchHover.svg';
const OpenFullScreen = '../assets/VRTutorial/openFullScreen.svg';
const OpenFullScreenHover = '../assets/VRTutorial/openFullScreenHover.svg';
const CloseFullScreen = '../assets/VRTutorial/closeFullScreen.svg';
const CloseFullScreenHover = '../assets/VRTutorial/closeFullScreenHover.svg.svg';
const GoOut = '../assets/VRTutorial/goOut.svg';
const GoOutHover = '../assets/VRTutorial/goOutHover.svg';



export const StyledVRTutorial = styled.div`
    position:fixed;
    bottom:60px;
    left:50%;
    z-index:10;
    width:370px;
    transform:translate(-50%,0%);
    @media(max-width:768px){
        width:250px;
        display: ${props => props.forRWDshowTutorial ? 'block' : 'none'};
    }
    .tutorial{
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;
        justify-content:space-around;
        & > div{
            width:50px;
            height:50px;
            cursor:pointer;
            background-size:contain;
            background-position:center;
            background-repeat:no-repeat;
            position:relative;
            img{
                width:100%;
                height:100%;
                object-fit:contain;
            }
            &:hover{
                .tutorialTooltip{
                    display:block;
                }
            }
            .tutorialTooltip{
                display:none;
                z-index:9999;
                position:absolute;
                top:-46px;
                left:50%;
                transform:translate(-50%,0);
                min-width:75px;
                word-break:keep-all;
                padding:6px 8px;
                text-align:center;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                background: #333333;
                border-radius: 4px;
                box-sizing:border-box;
                white-space:nowrap;
                &:before{
                    content:'';
                    position:absolute;
                    bottom:-11px;
                    left:50%;
                    transform:translate(-50%,0);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 7px 0 7px;
                    border-color: #333 transparent transparent transparent;
                }
            }
        }
        &_ARactive{
            background-image:url(${ARactive});
            &:hover{
                background-image:url(${ARactiveHover});
            }
        }
        &_Touch{
            background-image:url(${Touch});
            &:hover{
                background-image:url(${TouchHover});
            }
        }
        &_OpenFullScreen{
            background-image:url(${OpenFullScreen});
            &:hover{
                background-image:url(${OpenFullScreenHover});
            }
        }
        &_CloseFullScreen{
            background-image:url(${CloseFullScreen});
            &:hover{
                background-image:url(${CloseFullScreenHover});
            }
        }
        &_GoOut{
            background-image:url(${GoOut});
            &:hover{
                background-image:url(${GoOutHover});
            }
        }
    }
`;