const env = window.location.hostname === 'localhost' ? 'local' : process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV;

const SERVER = {
    local: {
        SERVER_URL: 'https://ar-api-dev.istaging.com',
        JSON: 'https://jsonplaceholder.typicode.com',
        GET_DOMAIN: 'localhost',
    },
    development: {
        SERVER_URL: 'https://ar-api-dev.istaging.com',
        JSON: 'https://jsonplaceholder.typicode.com',
        GET_DOMAIN: '.istaging.com',
    },
    test: {
        SERVER_URL: 'https://ar-api-test.istaging.com',
        JSON: 'https://jsonplaceholder.typicode.com',
        GET_DOMAIN: '.istaging.com',
    },
    production: {
        SERVER_URL: 'https://ar-api.istaging.com',
        JSON: 'https://jsonplaceholder.typicode.com',
        GET_DOMAIN: '.istaging.com',
    },
};

export default SERVER[env];
